import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import RockIt from '../../assets/images/rockit_cover-01.svg';

class RockItDetails extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (

            <div className="animated fadeIn">
            <div className="wrapper wrapper-project-details">
                <ul className="hList hList--justify">

                    <li>
                        <NavLink to="/socialNetworkingDetails">
                            <span className="icon-back"></span>
                        </NavLink>
                    </li>
                    <li className="align-right">


                        <ul className="hList">
                            <li className="align-right">
                                <NavLink to="/wcfpDetails">
                                    <span className="icon-next"></span>
                                </NavLink>
                            </li>
                        </ul>


                    </li>
                </ul>
                <div className="isHidden isVisible--medium">

                    <ul className="hList hList--justify hList--middle vr--x2">
                        <li className="border--right forty">
                            <h1>Rock-It Couture Logo Design</h1>
                            <h3> <span className="txt--uppercase txt--normal">CLIENT: </span> <span className="txt--serif">Rock-It Couture</span></h3>

                            <span className="icon-illustrator"></span>
                            <span className="icon-indesign"></span>

                        </li>

                        <li className="three"></li>
                        <li className="forty txt--normal">
Designed logo for start-up jewelry company.</li>

                    </ul>

                </div>

                <div className="isHidden--medium vr">
                    <h1>Rock It Couture Logo Design</h1>
                    <h3> <span className="txt--uppercase txt--normal">CLIENT: </span> <span className="txt--serif">Rock-It Couture</span></h3>

                    <div className="vr txt--normal">Designed logo for start-up jewelry company.</div>
                    <div>
                    <span className="icon-illustrator"></span>
                            <span className="icon-inDesign"></span>
                    </div>
  </div>
  <div className="logo-wrapper">
  <img src={RockIt} alt="Milewise" className="img logo-details-lg" />
  </div>
            </div>
            </div>


        );
    }
}

export default RockItDetails;


