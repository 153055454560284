import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import CallForVolunteers from '../../assets/images/callForVolunteers.gif'


class VolunteersDetails extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (

            <div className="animated fadeIn">
                <div className="wrapper wrapper-project-details">
                    <ul className="hList hList--justify">
                        <li>
                            <NavLink to="/welcomePartyDetails">
                                <span className="icon-back"></span>
                            </NavLink>
                        </li>
                        <li className="align-right">
                            <ul className="hList">
                                <li className="align-right">
                                    <NavLink to="bibDetails">
                                        <span className="icon-next"></span>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                    </ul>

                    <div className="isHidden isVisible--medium">
                        <ul className="hList hList--justify hList--middle vr--x2">
                            <li className="border--right forty">
                                <h1>Call for Volunteers Social Media Graphics</h1>
                                <h3> <span className="txt--uppercase txt--normal">CLIENT: </span> <span className="txt--serif">Emergency Nurses Association</span></h3>
                                <span className="icon-illustrator"></span>
                                <span className="icon-inDesign"></span>
                            </li>

                            <li className="three"></li>
                            <li className="forty txt--normal">
                                Created social media graphics for Twitter, Instagram, LinkedIn and Facebook to promote volunteering within ENA departments.</li>

                        </ul>
                    </div>
                    <div className="isHidden--medium vr">
                        <h1>Call for Volunteers Social Media Graphi</h1>
                        <h3> <span className="txt--uppercase txt--normal">CLIENT: </span> <span className="txt--serif">Emergency Nurses Association</span></h3>

                        <div className="vr txt--normal"> Created social media graphics for Twitter, Instagram, LinkedIn and Facebook to promote volunteering within ENA departments.</div>
                        <div>
                            <span className="icon-illustrator"></span>
                            <span className="icon-indesign"></span>
                        </div>
                    </div>

                    <div className="logo-wrapper">
                        <img src={CallForVolunteers} alt="CallForVolunteers" className="img logo-details-lg" />
                    </div>
                </div>
            </div>



        );
    }
}

export default VolunteersDetails;


