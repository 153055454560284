import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'

import RoadSmartImage from '../../assets/images/roadsmart_1.png'


class RoadSmartDetails extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (

            <div className="animated fadeIn">
            <div className="wrapper wrapper-project-details">
            <ul className="hList hList--justify">
      
                      <li>
                      <NavLink to="/championOfTreesDetails">
                            <span className="icon-back"></span>
                            </NavLink>
                      </li>
                      <li className="align-right">
      
                   
                          <ul className="hList">
                            <li className="align-right">
                            <NavLink to="/snowmobileDetails">
                            <span className="icon-next"></span>
                            </NavLink>
                            </li>                     
                          </ul>
                      
      
                      </li>
                    </ul>
                <div className="isHidden isVisible--medium">

                    <ul className="hList hList--justify hList--middle vr--x2">
                        <li className="border--right forty">
                            <h1>RoadSmart Android App Development</h1>
                            <h3> <span className="txt--uppercase txt--normal">CLIENT: </span> <span className="txt--serif">Allstate</span></h3>
                            <span className="icon-android"></span>
                            <span className="icon-sketch"></span>
                            <span className="icon-illustrator"></span>


                        </li>

                        <li className="three"></li>
                        <li className="forty txt--normal">
                            Assisted native developers by updating front-end, XML layouts and drawables for Android App Roadside Assistance App. Worked closely with UXAs, designers and developers to make sure assets were in sync with Allstate brand guidelines.

                        </li>

                    </ul>

                </div>

                <div className="isHidden--medium vr">
                    <h1>RoadSmart Android App Development</h1>
                    <h3> <span className="txt--uppercase txt--normal">CLIENT: </span> <span className="txt--serif">Allstate</span></h3>


                    <div className="vr txt--normal">Assisted native developers by updating front-end, XML layouts and drawables for Android App Roadside Assistance App. Worked closely with UXAs, designers and developers to make sure assets were in sync with Allstate brand guidelines.</div>


                    <div>
                        <span className="icon-android"></span>
                        <span className="icon-sketch"></span>
                        <span className="icon-illustrator"></span>

                    </div>
                </div>
                </div>
                <div className="wrapper project-wrapper">
                <img src={RoadSmartImage} alt="RoadSmart Android App Development One" className="img img--fill" />
                </div>


            </div>


        );
    }
}

export default RoadSmartDetails;


