import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import ProvenaCover from '../../assets/images/provena_1.png'
import ProvenaOutside from '../../assets/images/mercy_invite_outside.png'
import ProvenaInside from '../../assets/images/mercy_invite_inside.png'
import ProvenaReply from '../../assets/images/mercy_reply.png'
import ProvenaRaffle from '../../assets/images/mercy_raffle.png'
import ProvenaLetterhead from '../../assets/images/mercy_letterhead.png'

const description = "Mercy Ball Design Suite"
class ProvenaDetails extends Component {
  render() {

    return (


      <div className="animated fadeIn">
      <div className="wrapper wrapper-project-details">
      <ul className="hList hList--justify">

                <li>
                <NavLink to="/bibDetails">
                      <span className="icon-back"></span>
                      </NavLink>
                </li>
                <li className="align-right">

             
                    <ul className="hList">
                      <li className="align-right">
                      <NavLink to="/walkInWoodsDetails">
                      <span className="icon-next"></span>
                      </NavLink>
                      </li>                     
                    </ul>
                

                </li>
              </ul>
        <div className="isHidden isVisible--medium">

          <ul className="hList hList--justify hList--middle vr--x2">
            <li className="border--right forty">
              <h1>{description}</h1>
              <h3> <span className="txt--uppercase txt--normal">CLIENT: </span> <span className="txt--serif">Provena Hospital</span></h3>
              <span className="icon-illustrator"></span>
              <span className="icon-photoshop"></span>
              <span className="icon-inDesign"></span>

            </li>

            <li className="three"></li>
            <li className="forty txt--normal">
            Designed &ldquo;Mardi-Gras&rdquo; varying theme for Save the Date postcard, invite, raffle card, reply card and letterhead materials for Provena Hospital's Mercy Ball. Design included metallic ink and die cut.
</li>

          </ul>

        </div>

        <div className="isHidden--medium vr">
        <h1>{description}</h1>
          <h3> <span className="txt--uppercase txt--normal">CLIENT: </span> <span className="txt--serif">Provena Hospital</span></h3>

          <div className="vr txt--normal">Designed &ldquo;Mardi-Gras&rdquo; varying theme for Save the Date postcard, invite, raffle card, reply card and letterhead materials for Provena Hospital's Mercy Ball. Design included metallic ink and die cut.
</div>
          <div>      <span className="icon-illustrator"></span>
            <span className="icon-photoshop"></span>
            <span className="icon-inDesign"></span>
          </div>

        </div>
        </div>
        <div className="wrapper">
        <img src={ProvenaCover} alt="Provena Postcard" className="img img--fill" />
        <img src={ProvenaOutside} alt="Provena Invite Outside" className="img img--fill" />
        <img src={ProvenaInside} alt="Provena Invite Inside" className="img img--fill" />
        <img src={ProvenaReply} alt="Provena Invite Reply" className="img img--fill" />
        <img src={ProvenaRaffle} alt="Provena Invite Raffle" className="img img--fill" />
        <img src={ProvenaLetterhead} alt="Provena Invite Letterhead" className="img img--fill" />
      </div>
      </div>



    );
  }
}

export default ProvenaDetails;


