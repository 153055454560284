import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import WcfpLogo from '../../assets/images/wcfp.svg'

class WcfpDetails extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (

            <div className="animated fadeIn">
            <div className="wrapper wrapper-project-details">
                <ul className="hList hList--justify">

                    <li>
                        <NavLink to="/rockItDetails">
                            <span className="icon-back"></span>
                        </NavLink>
                    </li>
                    <li className="align-right">


                        <ul className="hList">
                            <li className="align-right">
                              {/* next */}
                            </li>
                        </ul>


                    </li>
                </ul>
                <div className="isHidden isVisible--medium">

                    <ul className="hList hList--justify hList--middle vr--x2">
                        <li className="border--right forty">
                            <h1>Windy City Financial Partners Logo Design</h1>
                            <h3> <span className="txt--uppercase txt--normal">CLIENT: </span> <span className="txt--serif">Windy City Financial Partners</span></h3>

                            <span className="icon-illustrator"></span>
                            <span className="icon-indesign"></span>

                        </li>

                        <li className="three"></li>
                        <li className="forty">
                        <div className="vr txt--normal">Logo design for financial institution.</div>
</li>

                    </ul>

                </div>

                <div className="isHidden--medium vr">
                    <h1>Windy City Financial Partners Logo Design</h1>
                    <h3> <span className="txt--uppercase txt--normal">CLIENT: </span> <span className="txt--serif">Windy City Financial Partners</span></h3>

                    <div className="vr txt--normal">Logo design for financial institution.</div>
                    <div>
                    <span className="icon-illustrator"></span>
                            <span className="icon-indesign"></span>
                    </div>
  </div>
  <div className="logo-wrapper">
  <img src={WcfpLogo} alt="Windy City Financial Partners Logo" className="img logo-details" />
  </div>
            </div>
            </div>


        );
    }
}

export default WcfpDetails;


