import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import 'moment-timezone';
import Torso from '../../assets/images/animation/headTorso.svg'
import Right from '../../assets/images/animation/rightSide.svg'
import Left from '../../assets/images/animation/leftSide.svg'

class About extends Component {

  render() {

    const moment = require('moment');

    const format = 'HH:mm a'
    let message = <h2>Hi there!</h2>

    // var time = moment() gives you current time. no format required.
    const time = moment(),
      morningTimeStart = moment('12:00 am', format),
      morningTimeEnd = moment('11:59 am', format),

      afternoonTimeStart = moment('12:00 pm', format),
      afternoonTimeEnd = moment('05:59 pm', format),

      eveningTimeStart = moment('05:00 pm', format),
      eveningTimeEnd = moment('11:59 pm', format)

    if (time.isBetween(morningTimeStart, morningTimeEnd)) {
      message = <h2>Good Morning!</h2>;
      console.log('Morning');

    }
    if (time.isBetween(afternoonTimeStart, afternoonTimeEnd)) {
      message = <h2>Good Afternoon!</h2>;
      console.log('Afternoon');

    }

    if (time.isBetween(eveningTimeStart, eveningTimeEnd)) {
      message = <h2>Good Evening!</h2>;
      console.log('Evening');

    }

    return (

      <div>


        <div className="txt--normal txt--page-title vr">About</div>
        <div className="isHidden isVisible--medium">
          <div className="cssgrid--container-about vr--x2">
            <div className="cssgrid-about cssgrid-left">
 <div className="icon-jd"></div>

            {/* <div className="animation-wrapper">
            <img src={Torso} alt="bib1" className="icon-headTorso" />
            <img src={Left} alt="bib1" className="icon-leftSide" />
            <img src={Right} alt="bib1" className="icon-rightSide" />
            
            
            </div>
           */}

              {/* <div className="icon-jd"></div> */}


            </div>
            <div className="cssgrid-about cssgrid-left">
              <div className="vr">
                <div className="wrapper">{message}</div>
              </div>
              <div className="vr">
                <div className="txt--normal">
                  <span className="txt--weight-bold txt--uppercase txt--size-18">You:</span><span className="txt--size-18">&nbsp;There's quite a variety of projects here.</span></div>

                <span className="txt--normal txt--weight-bold txt--uppercase txt--size-18">ME:</span><span className="txt--normal txt--size-18">&nbsp;Yes, I started out as a conceptual print designer. As my career progressed and as web development took off, I put an emphasis on learning everything I could related to design and web. I've worked with some fabulous folks in the graphic design, front-end and user experience world who truly helped me learn and grow along the way! My most recent position was front-end developer.</span>
              </div>
              <div className="vr">
                <div className="txt--normal">
                  <span className="txt--weight-bold txt--uppercase txt--size-18">You:</span><span className="txt--size-18">&nbsp;So with your overall skills you can design a logo AND build a website from scratch?</span></div>

                <span className="txt--normal txt--weight-bold txt--uppercase txt--size-18">ME:</span><span className="txt--normal txt--size-18">&nbsp;Yep, kinda like walking on stilts and dancing to the electric slide at the same time!</span>
              </div>
              <div className="vr">
                <div className="txt--normal">
                  <span className="txt--weight-bold txt--uppercase txt--size-18">You:</span><span className="txt--size-18">&nbsp;I need someone who has good design skills, a solid foundation in user experience, and can code front-end...so that's you?</span></div>

                <span className="txt--normal txt--weight-bold txt--uppercase txt--size-18">ME:</span><span className="txt--normal txt--size-18">&nbsp;That's me! <NavLink to="/contact">Contact me!</NavLink></span>

              </div>


            </div>


          </div>
        </div>
        <div className="isHidden--medium">
          <div className="wrapper wrapper-project-details">

            <div className="vr">

              <div className="icon-jd"></div>
              <div className="txt--normal">
                <span className="txt--weight-bold txt--uppercase txt--size-18">You:</span><span className="txt--size-18">&nbsp;There's quite a variety of projects here.</span></div>

              <span className="txt--normal txt--weight-bold txt--uppercase txt--size-18">ME:</span><span className="txt--normal txt--size-18">&nbsp;Yes, I started out as a conceptual print designer. As my career progressed and as web development took off, I put an emphasis on learning everything I could related to design and web. I've worked with some fabulous folks in the graphic design, front-end and user experience world who truly helped me learn and grow along the way! My most recent position was front-end developer.</span>
            </div>
            <div className="vr">
              <div className="txt--normal">
                <span className="txt--weight-bold txt--uppercase txt--size-18">You:</span><span className="txt--size-18">&nbsp;So with your overall skills you can design a logo AND build a website from scratch?</span></div>

              <span className="txt--normal txt--weight-bold txt--uppercase txt--size-18">ME:</span><span className="txt--normal txt--size-18">&nbsp;Yep, kinda like walking on stilts and dancing to the electric slide at the same time!</span>
            </div>
            <div className="vr">
              <div className="txt--normal">
              <span className="txt--weight-bold txt--uppercase txt--size-18">You:</span><span className="txt--size-18">&nbsp;I need someone who has good design skills, a solid foundation in user experience, and can code front-end...so that's you?</span></div>

              <span className="txt--normal txt--weight-bold txt--uppercase txt--size-18">ME:</span><span className="txt--normal txt--size-18">&nbsp; That's me! <NavLink to="/contact">Contact me!</NavLink></span>

            </div>
          </div>


        </div>

      </div>

    );
  }
}

export default About;


