import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import ReactGA from 'react-ga';

import SnowMobile from '../../assets/images/snowmobile.jpg';

class SnowmobileDetails extends Component {
    handleClickSnowmobileSite() {
        ReactGA.event({
          category: 'Sites',
          action: 'Clicked on View Snowmobile Site',
          label: 'Portfolio Sites'
        });
      }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div className="animated fadeIn">
            <div className="wrapper wrapper-project-details">
            <ul className="hList hList--justify">
      
                      <li>
                      <NavLink to="/roadsmartDetails">
                            <span className="icon-back"></span>
                            </NavLink>
                      </li>
                      <li className="align-right">
      
                   
                          <ul className="hList">
                            <li className="align-right">
                            <NavLink to="/gotBalanceDetails">
                            <span className="icon-next"></span>
                            </NavLink>
                            </li>                     
                          </ul>
                      
      
                      </li>
                    </ul>
                <div className="isHidden isVisible--medium">

                    <ul className="hList hList--justify hList--middle vr--x2">
                        <li className="border--right forty">
                            <h1>Allstate Ad Greensock Animation</h1>
                            <h3> <span className="txt--uppercase txt--normal">CLIENT: </span> <span className="txt--serif">Allstate</span></h3>
                            <span className="icon-html"></span>
                            <span className="icon-less"></span>
                            <span className="icon-greensock"></span>

                        </li>

                        <li className="three"></li>
                        <li className="forty txt--normal">
                        <div className="highlight vr"><a target="_blank" rel="noopener noreferrer" href="http://www.jenzdesigninc.com/webProjects/SnowMobile_greensock/SnowMobile_300x250_final/deploy/index-300x250.html" onClick={() => { this.handleClickSnowmobileSite() }}>View Site</a>
                </div>
                            Converted Flash banner animation into HTML5 by using Greensock animation library.
</li>

                    </ul>

                </div>

                <div className="isHidden--medium vr">
                    <h1>Allstate Ad Greensock Animation</h1>
                    <h3> <span className="txt--uppercase txt--normal">CLIENT: </span> <span className="txt--serif">Allstate</span></h3>
                    <div className="highlight vr"><a target="_blank" rel="noopener noreferrer" href="http://www.jenzdesigninc.com/webProjects/SnowMobile_greensock/SnowMobile_300x250_final/deploy/index-300x250.html" onClick={() => { this.handleClickSnowmobileSite() }}>View Site</a>
                </div>
                    <div className="vr txt--normal">Converted Flash banner animation into HTML5 by using Greensock animation library.</div>

                    <div>
                        <span className="icon-html"></span>
                        <span className="icon-less"></span>
                        <span className="icon-greensock"></span>
                    </div>
                </div>
                <div className="vr--x2">
                    <div className="wrapper project-wrapper">
                        <img src={SnowMobile} alt="Allstate Snowmobile Ad" className="img" />
                    </div>
                </div>
            </div>
            </div>


        );
    }
}

export default SnowmobileDetails;


