import React, { Component } from 'react';

import { Route } from 'react-router-dom'
import Header from '../header/Header';
import Work from '../work/Work';
import About from '../about/About';
import Contact from '../contact/Contact';
import Footer from '../footer/Footer';

import MakeItHappenDetails from '../makeItHappenDetails/MakeItHappenDetails';
import WelcomePartyDetails from '../welcomePartyDetails/WelcomePartyDetails';
import VolunteersDetails from '../volunteersDetails/VolunteersDetails';

import BibDetails from '../bibDetails/BibDetails';
import WalkInWoodsDetails from '../walkInWoodsDetails/WalkInWoodsDetails';
import ProvenaDetails from '../provenaDetails/ProvenaDetails';
import MilewiseDetails from '../milewiseDetails/MilewiseDetails';
import KeyCollectorDetails from '../keyCollectorDetails/KeyCollectorDetails';
import ChampionOfTreesDetails from '../championOfTreesDetails/ChampionOfTreesDetails';
import RoadSmartDetails from '../roadSmartDetails/RoadSmartDetails';
import SnowmobileDetails from '../snowmobileDetails/SnowmobileDetails';
// import NatureConnectsDetails from '../natureConnectsDetails/NatureConnectsDetails';
import GotBalanceDetails from '../gotBalanceDetails/GotBalanceDetails';
import OaktoberDetails from '../oaktoberDetails/OaktoberDetails';
import SocialNetworkingDetails from '../socialNetworkingDetails/SocialNetworkingDetails';
import RockItDetails from '../rockItDetails/RockItDetails';
import WcfpDetails from '../wcfpDetails/WcfpDetails';

import ReactGA from 'react-ga';


ReactGA.initialize('UA-109671774-1');
ReactGA.pageview(window.location.pathname + window.location.search);

export function fireTracking(nextState) {
  
     const { pathname } = nextState.location // this gives you the next URL
  
      ReactGA.pageview(pathname)
  
    }

class Main extends Component {

  render() {
    return (
 <div>
  <Header/>
    <div className="wrapper">
    <Route path="/" onEnter={ fireTracking } exact component={Work} />
    <Route path="/work" onEnter={ fireTracking } exact component={Work} />
    <Route path="/about" onEnter={ fireTracking } component={About} />
    <Route path="/contact" onEnter={ fireTracking } component={Contact} />

    <Route path="/makeItHappenDetails" onEnter={ fireTracking } component={MakeItHappenDetails} />
    <Route path="/welcomePartyDetails" onEnter={ fireTracking } component={WelcomePartyDetails} />
    <Route path="/volunteersDetails" onEnter={ fireTracking } component={VolunteersDetails} />

    <Route path="/bibDetails" onEnter={ fireTracking } component={BibDetails} />
    <Route path="/walkInWoodsDetails" onEnter={ fireTracking } component={WalkInWoodsDetails} />
    <Route path="/provenaDetails" onEnter={ fireTracking } component={ProvenaDetails} />
    <Route path="/milewiseDetails" onEnter={ fireTracking } component={MilewiseDetails} />
    <Route path="/keyCollectorDetails" onEnter={ fireTracking } component={KeyCollectorDetails} />
    <Route path="/championOfTreesDetails" onEnter={ fireTracking } component={ChampionOfTreesDetails} />
    {/* <Route path="/natureConnectsDetails" onEnter={ fireTracking } component={NatureConnectsDetails} /> */}
    <Route path="/gotBalanceDetails" onEnter={ fireTracking } component={GotBalanceDetails} />
    <Route path="/oaktoberDetails" onEnter={ fireTracking } component={OaktoberDetails} />
    <Route path="/roadSmartDetails" onEnter={ fireTracking } component={RoadSmartDetails} />
    <Route path="/snowmobileDetails" onEnter={ fireTracking } component={SnowmobileDetails} />
    <Route path="/socialNetworkingDetails" onEnter={ fireTracking } component={SocialNetworkingDetails} />
    <Route path="/rockItDetails" onEnter={ fireTracking } component={RockItDetails} />
    <Route path="/wcfpDetails" onEnter={ fireTracking } component={WcfpDetails} />

  </div>

  <Footer/>
  </div>
    
    );
  }
}

export default Main;


