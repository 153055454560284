import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import MakeItHappenLogo from '../../assets/images/makeItHappen_2.gif'
import MakeItHappenOne from '../../assets/images/EN19_trifiold1.jpg'
import MakeItHappenTwo from '../../assets/images/EN19_trifiold2.jpg'


class MakeItHappenDetails extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (

            <div className="animated fadeIn">
            <div className="wrapper wrapper-project-details">
            <ul className="hList hList--justify">
      
                      <li>
                      {/* <NavLink to="/snowmobileDetails">
                            <span className="icon-back"></span>
                            </NavLink> */}
                      </li>
                      <li className="align-right">
      
                   
                          <ul className="hList">
                            <li className="align-right">
                            <NavLink to="/welcomePartyDetails">
                            <span className="icon-next"></span>
                            </NavLink>
                            </li>                     
                          </ul>
                      
      
                      </li>
                    </ul>

                <div className="isHidden isVisible--medium">

                    <ul className="hList hList--justify hList--middle vr--x2">
                        <li className="border--right forty">
                            <h1>#MakeItHappen Logomark</h1>
                            <h3> <span className="txt--uppercase txt--normal">CLIENT: </span> 
                            <span className="txt--serif">Emergency Nurses Association</span></h3>
                           
                        <span className="icon-illustrator"></span>
                        <span className="icon-inDesign"></span>
                        <span className="icon-photoshop"></span>


                        </li>

                        <li className="three"></li>
                        <li className="forty txt--normal">
Designed logomark for use on various promotional material, including social media, marketing pieces and swag.
                        </li>

                    </ul>

                </div>

                <div className="isHidden--medium vr">
                    <h1>#MakeItHappen Logomark</h1>
                    <h3> <span className="txt--uppercase txt--normal">CLIENT: </span> 
                    <span className="txt--serif">Emergency Nurses Association</span></h3>


                    <div className="vr txt--normal">Designed logomark for use on various promotional material, including social media, marketing pieces and swag.</div>

                 
                        <span className="icon-illustrator"></span>
                        <span className="icon-inDesign"></span>
                        <span className="icon-photoshop"></span>

                    </div>
                    <div className="logo-wrapper">
                    <img src={MakeItHappenOne} alt="Make It Happen" className="img" />
                    <img src={MakeItHappenTwo} alt="Make It Happen" className="img" />
                    <img src={MakeItHappenLogo} alt="Make It Happen" className="img" />
                    
                    </div>
                </div>
                </div>
            




        );
    }
}

export default MakeItHappenDetails;


