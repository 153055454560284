import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import ReactGA from 'react-ga';

import MilewiseMobile from '../../assets/images/milewise_mobile.png';
import MilewiseDesktop from '../../assets/images/milewise_desktop.png';

class MilewiseDetails extends Component {
  handleClickMilewiseSite() {
    ReactGA.event({
      category: 'Sites',
      action: 'Clicked on View Milewise Site',
      label: 'Portfolio Sites'
    });
  }
  componentDidMount() {
    window.scrollTo(0, 0);
}
  render() {
    return (

      <div className="animated fadeIn">
      <div className="wrapper wrapper-project-details">
      <ul className="hList hList--justify">

                <li>
                <NavLink to="/walkInWoodsDetails">
                      <span className="icon-back"></span>
                      </NavLink>
                </li>
                <li className="align-right">

             
                    <ul className="hList">
                      <li className="align-right">
                      <NavLink to="/keyCollectorDetails">
                      <span className="icon-next"></span>
                      </NavLink>
                      </li>                     
                    </ul>
                

                </li>
              </ul>
          <div className="isHidden isVisible--medium">


            <ul className="hList hList--justify hList--middle vr--x2">
              <li className="border--right forty">
                <h1>Milewise Web Application</h1>
                <h3> <span className="txt--uppercase txt--normal">CLIENT: </span> <span className="txt--serif">Allstate</span></h3>
                <span className="icon-html"></span>
                <span className="icon-angular"></span>
                <span className="icon-less"></span>
                <span className="icon-googleMaps"></span>
                <span className="icon-highcharts"></span>


              </li>

              <li className="three"></li>
              <li className="forty vr txt--normal">
                <div className="highlight vr"><a target="_blank" rel="noopener noreferrer" href="http://www.jenzdesigninc.com/webProjects/MT-MilewiseV1-3/index.tpl.html" onClick={() => { this.handleClickMilewiseSite() }}>View Site</a>
                </div>
                Milewise features a &ldquo;Pay-Per-Use&rdquo; insurance model where drivers are only charged insurance based on the amount of miles driven. I was the web-developer that was part of a project team that worked closely with visual designers, user experience architects and researchers, content specialists, native devs, and back-end devs. Front-end code was developed in angular and I paired with back-end devs in an XP environment to incorporate front-end code. Customized location maps and charts were developed in Google maps and Highcharts APIs.

            </li>

            </ul>


          </div>

          <div className="isHidden--medium vr">
            <h1>Milewise Web Application</h1>
            <h3 className="vr"> <span className="txt--uppercase txt--normal">CLIENT: </span> <span className="txt--serif">Allstate</span></h3>
            <div className="highlight vr"><a target="_blank" rel="noopener noreferrer" href="http://www.jenzdesigninc.com/webProjects/MT-MilewiseV1-3/index.tpl.html" onClick={() => { this.handleClickMilewiseSite() }}>View Site</a>
            </div>
            <p className="vr txt--normal">Milewise features a &ldquo;Pay-Per-Use&rdquo; insurance model where drivers are only charged insurance based on the amount of miles driven. I was the web-developer that was part of a project team that worked closely with visual designers, user experience architects and researchers, content specialists and back-end developers. Front-end code was developed in angular and I paired with back-end developers in an XP environment to educate and incorporate front-end code. Customized location maps and charts were developed in Google maps and Highcharts APIs.</p>



            <span className="icon-html"></span>
            <span className="icon-angular"></span>
            <span className="icon-less"></span>
            <span className="icon-googleMaps"></span>
            <span className="icon-highcharts"></span>



          </div>
        </div>
        <div className="wrapper">
          <img src={MilewiseMobile} alt="Milewise Desktop" className="img img--fill" />
          <img src={MilewiseDesktop} alt="Milewise Mobile" className="img img--fill" />

        </div>
      </div>


    );
  }
}

export default MilewiseDetails;


