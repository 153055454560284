
import React from 'react';
import { HashRouter } from 'react-router-dom'
import Main from './components/main/Main';

import './assets/styles/app.css';
import ReactGA from 'react-ga';


  ReactGA.initialize('UA-109671774-1');

  ReactGA.pageview(window.location.pathname + window.location.search);


const App = () => (
  

  <div>
    <HashRouter>
    <Main />
    </HashRouter>
  </div>
);


export default App
