import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import WalkInWoodsCover from '../../assets/images/walkInTheWoods.png'

class WalkInWoods extends Component {
    render() {
        return (

            <div className="animated fadeIn">
      <div className="wrapper wrapper-project-details">
      <ul className="hList hList--justify">

                <li>
                <NavLink to="/provenaDetails">
                      <span className="icon-back"></span>
                      </NavLink>
                </li>
                <li className="align-right">

             
                    <ul className="hList">
                      <li className="align-right">
                      <NavLink to="/milewiseDetails">
                      <span className="icon-next"></span>
                      </NavLink>
                      </li>                     
                    </ul>
                

                </li>
              </ul>
                <div className="isHidden isVisible--medium">

                    <ul className="hList hList--justify hList--middle vr--x2">
                        <li className="border--right forty">
                            <h1>TShirt Design</h1>
                            <h3> <span className="txt--uppercase txt--normal">CLIENT: </span> <span className="txt--serif">The Morton Arboretum</span></h3>

                            <span className="icon-illustrator"></span>
                            <span className="icon-indesign"></span>

                        </li>

                        <li className="three"></li>
                        <li className="forty txt--normal">
                        The Morton Arboretum T-shirt design option for promotional theme – &ldquo;<em>We're more than just a Walk in the Woods&rdquo;</em>.
</li>

                    </ul>

                </div>

                <div className="isHidden--medium vr">
                    <h1>TShirt Design</h1>
                    <h3> <span className="txt--uppercase txt--normal">CLIENT: </span> <span className="txt--serif">The Morton Arboretum</span></h3>

                    <div className="vr txt--normal">The Morton Arboretum T-shirt design option for promotional theme – &ldquo;<em>We're more than just a Walk in the Woods&rdquo;</em>.</div>
                    <div>
                    <span className="icon-illustrator"></span>
                            <span className="icon-indesign"></span>
                    </div>
  </div>
  </div>
  <div className="wrapper">
  <img src={WalkInWoodsCover} alt="Milewise" className="img img--fill" />
            </div>
            </div>


        );
    }
}

export default WalkInWoods;


