import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import SocialOne from '../../assets/images/socialNetworking.svg'
import SocialTwo from '../../assets/images/socialNetworking_2.svg'
import SocialThree from '../../assets/images/socialNetworking_3.svg'


class SocialNetworkingDetails extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (

            <div className="animated fadeIn">
            <div className="wrapper wrapper-project-details">
                <ul className="hList hList--justify">

                    <li>
                        <NavLink to="/oaktoberDetails">
                            <span className="icon-back"></span>
                        </NavLink>
                    </li>
                    <li className="align-right">


                        <ul className="hList">
                            <li className="align-right">
                                <NavLink to="/rockItDetails">
                                    <span className="icon-next"></span>
                                </NavLink>
                            </li>
                        </ul>


                    </li>
                </ul>
                <div className="isHidden isVisible--medium">

                    <ul className="hList hList--justify hList--middle vr--x2">
                        <li className="border--right forty">
                            <h1>Social Networking Logo Options</h1>
                            <h3> <span className="txt--uppercase txt--normal">CLIENT: </span> <span className="txt--serif">Allstate</span></h3>

                            <span className="icon-illustrator"></span>
                        

                        </li>

                        <li className="three"></li>
                        <li className="forty">
                        Desigined logo options for internal Social Networking team within Allstate.
</li>

                    </ul>

                </div>

                <div className="isHidden--medium vr">
                <h1>Social Networking Logo Options</h1>
                <h3> <span className="txt--uppercase txt--normal">CLIENT: </span> <span className="txt--serif">Allstate</span></h3>
                
                    <div className="vr"> Desigined logo options for internal Social Networking team within Allstate.</div>
                    <div>
                    <span className="icon-illustrator"></span>
             
                    </div>
  </div>
  <div className="logo-wrapper">
  <div><img src={SocialOne} alt="Social Networking Option 1" className="img logo-details vr--x3" /></div>

  <div><img src={SocialTwo} alt="Social Networking Option 2"  className="img logo-details vr--x5" /></div>

  <div><img src={SocialThree} alt="Social Networking Option 3"  className="img logo-details vr--x5" /></div>

  </div>

            </div>
</div>

        );
    }
}

export default SocialNetworkingDetails;


