import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import ReactGA from 'react-ga';
import WelcomePartyAnimationVideoOne from '../../assets/images/welcomeParty_2.jpg'
import WelcomePartyAnimationVideoTwo from '../../assets/images/welcomeParty_socialMedia.jpg'


class WelcomPartyDetails extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    handleClickAnimation() {
        ReactGA.event({
          category: 'Animation',
          action: 'Clicked on View Animation',
          label: 'Portfolio Sites'
        });
      }
    render() {
        return (

            <div className="animated fadeIn">
            <div className="wrapper wrapper-project-details">
            <ul className="hList hList--justify">
      
                      <li>
                      <NavLink to="/makeItHappenDetails">
                            <span className="icon-back"></span>
                            </NavLink>
                      </li>
                      <li className="align-right">
      
                   
                          <ul className="hList">
                            <li className="align-right">
                            <NavLink to="/volunteersDetails">
                            <span className="icon-next"></span>
                            </NavLink>
                            </li>                     
                          </ul>
                      
      
                      </li>
                    </ul>

                <div className="isHidden isVisible--medium">

                    <ul className="hList hList--justify hList--middle vr--x2">
                        <li className="border--right forty">
                            <h1>ENA Welcome Party Video Animation</h1>
                            <h3> <span className="txt--uppercase txt--normal">CLIENT: </span> 
                            <span className="txt--serif">Emergency Nurses Association</span></h3>
                           
                        <span className="icon-illustrator"></span>
                        <span className="icon-photoshop"></span>


                        </li>

                        <li className="three"></li>
                        <li className="forty txt--normal">
                        <div className="highlight vr"><a target="_blank" rel="noopener noreferrer" href="https://www.jenzdesigninc.com/webProjects/welcomeParty_jh.mp4" onClick={() => { this.handleClickAnimation() }}>View Animation</a>
            </div>
            Created 12 second animation from existing design and artwork featured on giant video board for Welcome Night Party at annual conference.

                        </li>
                        

                    </ul>

                </div>

                <div className="isHidden--medium vr">
                    <h1>ENA Welcome Party Video Animation</h1>
                    <h3> <span className="txt--uppercase txt--normal">CLIENT: </span> 
                    <span className="txt--serif">Emergency Nurses Association</span></h3>


                    <div className="vr txt--normal">Created 12 second animation from existing design and artwork featured on giant video board for Welcome Night Party at annual conference.</div>

                 
                        <span className="icon-illustrator"></span>
                        <span className="icon-photoshop"></span>

                    </div>
                    <div className="logo-wrapper">
                    
                    <img src={WelcomePartyAnimationVideoTwo} alt="Welcome Party Social Media" className="img" />
                    <br /> <br />
           
                 
                    
                    </div>
                </div>
                </div>
            




        );
    }
}

export default WelcomPartyDetails;


