import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import OaktoberOne from '../../assets/images/oaktober.svg'
import OaktoberTwo from '../../assets/images/oaktober_2.svg'
import OaktoberThree from '../../assets/images/oaktober_3.svg'

class KeyCollectorDetails extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (

            <div className="animated fadeIn">
                <div className="wrapper wrapper-project-details">
                    <ul className="hList hList--justify">

                        <li>
                            <NavLink to="/gotBalanceDetails">
                                <span className="icon-back"></span>
                            </NavLink>
                        </li>
                        <li className="align-right">


                            <ul className="hList">
                                <li className="align-right">
                                    <NavLink to="/socialNetworkingDetails">
                                        <span className="icon-next"></span>
                                    </NavLink>
                                </li>
                            </ul>


                        </li>
                    </ul>
                    <div className="isHidden isVisible--medium">

                        <ul className="hList hList--justify hList--middle vr--x2">
                            <li className="border--right forty">
                                <h1>Oaktober Logo Options</h1>
                                <h3> <span className="txt--uppercase txt--normal">CLIENT: </span> <span className="txt--serif">Chicago Regional Trees Initiative</span></h3>
                                <span className="icon-illustrator"></span>


                            </li>

                            <li className="three"></li>
                            <li className="forty txt--normal">
                                Designed logo to help create awareness of the environmental effects troubling oak trees.

                        </li>

                        </ul>

                    </div>

                    <div className="isHidden--medium vr">
                        <h1>Oaktober Logo Options</h1>
                        <h3> <span className="txt--uppercase txt--normal">CLIENT: </span> <span className="txt--serif">Chicago Regional Trees Initiative</span></h3>


                        <div className="vr txt--normal">Designed logo to help create awareness of the environmental effects troubling oak trees.</div>


                        <div>
                            <span className="icon-illustrator"></span>

                        </div>
                    </div>
                    <div className="logo-wrapper">
                        <div> <img src={OaktoberOne} alt="Oaktober One" className="img logo-details vr--x3" /></div>
                        <div><img src={OaktoberTwo} alt="Oaktober Two" className="img logo-details vr--x3" /></div>
                        <div><img src={OaktoberThree} alt="Oaktober Three" className="img logo-details" /></div>
                    </div>

                </div>
            </div>


        );
    }
}

export default KeyCollectorDetails;


