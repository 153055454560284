import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import GotBalanceLogo from '../../assets/images/gotBalance.svg'


class GotBalanceDetails extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (

            <div className="animated fadeIn">
            <div className="wrapper wrapper-project-details">
            <ul className="hList hList--justify">
      
                      <li>
                      <NavLink to="/snowmobileDetails">
                            <span className="icon-back"></span>
                            </NavLink>
                      </li>
                      <li className="align-right">
      
                   
                          <ul className="hList">
                            <li className="align-right">
                            <NavLink to="/oaktoberDetails">
                            <span className="icon-next"></span>
                            </NavLink>
                            </li>                     
                          </ul>
                      
      
                      </li>
                    </ul>

                <div className="isHidden isVisible--medium">

                    <ul className="hList hList--justify hList--middle vr--x2">
                        <li className="border--right forty">
                            <h1>Beachbody Coach <br />Promotional Logo</h1>
                            <h3> <span className="txt--uppercase txt--normal">CLIENT: </span> <span className="txt--serif">Team Beachbody Coach</span></h3>
                            <span className="icon-illustrator"></span>


                        </li>

                        <li className="three"></li>
                        <li className="forty txt--normal">
Designed logo which was used for social media promotion for Beachbody customers.
                        </li>

                    </ul>

                </div>

                <div className="isHidden--medium vr">
                    <h1>Beachbody Coach Promotional Logo</h1>
                    <h3> <span className="txt--uppercase txt--normal">CLIENT: </span> <span className="txt--serif">Team Beachbody Coach</span></h3>


                    <div className="vr txt--normal">Designed logo which was used for social media promotion for Beachbody customers.</div>

                 
                        <span className="icon-illustrator"></span>

                    </div>
                    <div className="logo-wrapper">
                    <img src={GotBalanceLogo} alt="Got Balance logo" className="img logo-details" />
                    </div>
                </div>
                </div>
            




        );
    }
}

export default GotBalanceDetails;


