import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import ReactGA from 'react-ga';

import KeyCollectorMobile from '../../assets/images/keyCollector_mobile.png';
import KeyCollectorDesktop from '../../assets/images/keyCollector_desktop.png';

class KeyCollectorDetails extends Component {
    handleClickKeyCollectorSite() {
        ReactGA.event({
          category: 'Sites',
          action: 'Clicked on View Key Collector Site',
          label: 'Portfolio Sites'
        });
      }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (

           
            <div className="animated fadeIn">
            <div className="wrapper wrapper-project-details">
            <ul className="hList hList--justify">
      
                      <li>
                      <NavLink to="/milewiseDetails">
                            <span className="icon-back"></span>
                            </NavLink>
                      </li>
                      <li className="align-right">
      
                   
                          <ul className="hList">
                            <li className="align-right">
                            <NavLink to="/championOfTreesDetails">
                            <span className="icon-next"></span>
                            </NavLink>
                            </li>                     
                          </ul>
                      
      
                      </li>
                    </ul>
                <div className="isHidden isVisible--medium">

                    <ul className="hList hList--justify hList--middle vr--x2">
                        <li className="border--right forty">
                            <h1>KeyCollector Comics Promotional Website</h1>
                            <h3> <span className="txt--uppercase txt--normal">CLIENT: </span> <span className="txt--serif">KeyCollector Comics</span></h3>
                            <span className="icon-react"></span>
                            <span className="icon-sass"></span>
                            <span className="icon-illustrator"></span>
                            <span className="icon-photoshop"></span>

                        </li> 

                        <li className="three"></li>
                        <li className="forty txt--normal">
                        <div className="highlight vr"><a target="_blank" rel="noopener noreferrer" href="http://www.keycollectorcomics.com" onClick={() => { this.handleClickKeyCollectorSite() }}>View Site</a>
            </div>
                            Designed and coded front-end promotional website for launch of KeyCollector Comic book app on Google Play and the App Store. Site was developed using React.

                    
                        </li>

                    </ul>

                </div>

                <div className="isHidden--medium vr">
                    <h1>KeyCollector Comics Promotional Website</h1>
                    <h3 className="vr"> <span className="txt--uppercase txt--normal">CLIENT: </span> <span className="txt--serif">KeyCollector Comics</span></h3>

                    <div className="highlight vr txt--normal"><a target="_blank" rel="noopener noreferrer" href="http://www.keycollectorcomics.com" onClick={() => { this.handleClickKeyCollectorSite() }}>View Site</a>
            </div>
                    <div className="vr">Designed and coded front-end promotional website for launch of KeyCollector Comic book app on Google Play and the App Store. Site was developed using React.</div>

                   
                    <div>
                        <span className="icon-react"></span>
                        <span className="icon-sass"></span>
                        <span className="icon-illustrator"></span>
                        <span className="icon-photoshop"></span>
                    </div>
                </div>
                </div>
                <div className="wrapper">
                    <img src={KeyCollectorDesktop} alt="Milewise Desktop" className="img img--fill" />
                    <img src={KeyCollectorMobile} alt="Milewise Mobile" className="img img--fill" />
                </div>

            </div>


        );
    }
}

export default KeyCollectorDetails;


