import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import ReactGA from 'react-ga';

import BibCover from '../../assets/images/bib_cover.png'
import BibCover1 from '../../assets/images/bib_ver2.jpg'
import BibCover2 from '../../assets/images/bib_ver2-1.png'
import BibCover3 from '../../assets/images/bib_ver2-2.png'
import BibCover4 from '../../assets/images/bib_ver2-3.png'
import BibCover5 from '../../assets/images/bib_ver2-4.png'

const description = "Dinner Invitation Design Suite"

class BibDetails extends Component {


  handleClickBibFold() {
    ReactGA.event({
      category: 'Sites',
      action: 'Clicked on View Fold Of the Week BIB',
      label: 'Portfolio Sites'
    });

  }



  render() {



    return (

      <div className="animated fadeIn">

        <div className="wrapper wrapper-project-details">

        <ul className="hList hList--justify">
      
                      <li>
                      <NavLink to="/volunteersDetails">
                            <span className="icon-back"></span>
                            </NavLink>
                      </li>
                      <li className="align-right">
      
                   
                          <ul className="hList">
                            <li className="align-right">
                            <NavLink to="/provenaDetails">
                            <span className="icon-next"></span>
                            </NavLink>
                            </li>                     
                          </ul>
                      
      
                      </li>
                    </ul>
  



          <div className="isHidden isVisible--medium">

            <ul className="hList hList--justify hList--middle vr--x2">
              <li className="border--right forty">
                <h1>{description}</h1>
                <h3> <span className="txt--uppercase txt--normal">CLIENT: </span> <span className="txt--serif">The Morton Arboretum</span></h3>

                <span className="icon-photoshop"></span>
                <span className="icon-illustrator"></span>
                <span className="icon-inDesign"></span>

              </li>

              <li className="three"></li>
              <li className="forty txt--normal">
                <div className="highlight vr">Project was featured in <a href="https://www.youtube.com/embed/peZN7YG_vCM?feature=oembed" rel="noopener noreferrer" target="_blank" onClick={() => { this.handleClickBibFold() }}>Fold of the Week</a>
                </div>
                Design theme consisted of tagline, 	&ldquo;Brilliance in Bloom&rdquo;, hydrangeas and bright colors. As the reader unfolds the invitation, neon inks, laser die-cut and a spot varnish give the invitation a bold, bright look. The RSVP and supporting material was secured with two half-moon tabs. Invite was sent in custom neon ink envelope.
</li>

            </ul>

          </div>

          <div className="isHidden--medium vr">
          <h1>{description}</h1>
            <h3> <span className="txt--uppercase txt--normal">CLIENT: </span> <span className="txt--serif">The Morton Arboretum</span></h3>
            <div className="highlight vr txt--normal">Project was featured in <a href="https://www.youtube.com/embed/peZN7YG_vCM?feature=oembed" rel="noopener noreferrer" target="_blank" onClick={() => { this.handleClickBibFold() }}>Fold of the Week</a>
            </div>
            <div className="vr txt--normal">
              Design theme consisted of tagline, 	&ldquo;Brilliance in Bloom&rdquo;, hydrangeas and bright colors. As the reader unfolds the invitation, neon inks, laser die-cut and a spot varnish give the invitation a bold, bright look. The RSVP and supporting material was secured with two half-moon tabs. Invite was sent in custom neon ink envelope.
    </div>

            <div>
              <span className="icon-photoshop"></span>
              <span className="icon-illustrator"></span>
              <span className="icon-inDesign"></span></div>

          </div>
        </div>


        <div className="wrapper">

          <img src={BibCover} alt="bib1" className="img img--fill" />

          {/* <div class="overlay"> */}
          <img src={BibCover1} alt="bib1" className="img img--fill" />
          {/* </div> */}
          <img src={BibCover2} alt="Bib2" className="img img--fill" />
          <img src={BibCover3} alt="Bib3" className="img img--fill" />
          <img src={BibCover4} alt="Bib3" className="img img--fill" />
          <img src={BibCover5} alt="Bib3" className="img img--fill" />


        </div>

      </div>



    );
  }
}

export default BibDetails;


