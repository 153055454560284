import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';
import WelcomePartyAnimation from '../../assets/images/welcomeParty_cover2.jpg'

class Work extends Component {
  handleClickMakeItHappen() {
    ReactGA.event({
      category: 'Work',
      action: 'Clicked on MakeItHappen',
      label: 'Logo Project'
    });
  }

  handleClickWelcomeParty() {
    ReactGA.event({
      category: 'Work',
      action: 'Clicked on WelcomeParty',
      label: 'Animation Project'
    });
  }

  handleClickVolunteersDetails() {
    ReactGA.event({
      category: 'Work',
      action: 'Clicked on CallForVolunteers',
      label: 'Social Media Project'
    });
  }

  handleClickTriFold() {
    ReactGA.event({
      category: 'Work',
      action: 'Clicked on Trifold',
      label: 'Design Project'
    });
  }


  handleClickBib() {
    ReactGA.event({
      category: 'Work',
      action: 'Clicked on Bib',
      label: 'Design Project'
    });
  }
  handleClickProvena() {
    ReactGA.event({
      category: 'Work',
      action: 'Clicked on Provena',
      label: 'Design Project'
    });
  }
  handleClickWalkInWoods() {
    ReactGA.event({
      category: 'Work',
      action: 'Clicked on Walk In Woods',
      label: 'Design Project'
    });
  }

  handleClickMilewise() {
    ReactGA.event({
      category: 'Work',
      action: 'Clicked on Milewise',
      label: 'Web Project'
    });
  }

  handleClickKeyCollector() {
    ReactGA.event({
      category: 'Work',
      action: 'Clicked on Key Collector',
      label: 'Web Project'
    });
  }

  handleClickChampionOfTrees() {
    ReactGA.event({
      category: 'Work',
      action: 'Clicked on Champion of Trees',
      label: 'Web Project'
    });
  }

  handleClickRoadsmart() {
    ReactGA.event({
      category: 'Work',
      action: 'Clicked on Roadsmart',
      label: 'Web Project'
    });
  }

  handleClickSnowmobile() {
    ReactGA.event({
      category: 'Work',
      action: 'Clicked on Snowmobile',
      label: 'Web Project'
    });
  }

  handleClickGotBalance() {
    ReactGA.event({
      category: 'Work',
      action: 'Clicked on Got Balance',
      label: 'Logo Project'
    });
  }

  // handleClickNatureConnects() {
  //   ReactGA.event({
  //     category: 'Work',
  //     action: 'Clicked on Nature Connects',
  //     label: 'Logo Project'
  //   });
  // }

  handleClickOaktober() {
    ReactGA.event({
      category: 'Work',
      action: 'Clicked on Oaktober',
      label: 'Logo Project'
    });
  }

  handleClickSocialNetworking() {
    ReactGA.event({
      category: 'Work',
      action: 'Clicked on Social Networking',
      label: 'Web Project'
    });
  }

  handleClickRockIt() {
    ReactGA.event({
      category: 'Work',
      action: 'Clicked on RockIt',
      label: 'Logo Project'
    });
  }

  handleClickWCFP() {
    ReactGA.event({
      category: 'Work',
      action: 'Clicked on WCFP',
      label: 'Logo Project'
    });
  }

  render() {
    return (

      <div className="vr--x3 animated fadeIn">
        <div className="txt--normal txt--page-title vr">RECENT WORK</div>
        <div className="cssgrid--container vr--x2">
          <div className="cssgrid--project">
            <Link to="/makeItHappenDetails" onClick={() => { this.handleClickMakeItHappen() }}>
              <div className="icon-makeItHappen_gray"></div>
            </Link>
            

          </div>

          <div className="cssgrid--project">
            <Link to="/welcomePartyDetails" onClick={() => { this.handleClickWelcomeParty() }}>
            <img src={WelcomePartyAnimation} alt="Welcome Party Social Media" className="img" />
            </Link>
            

          </div>


          <div className="cssgrid--project">
            <Link to="/volunteersDetails" onClick={() => { this.handleClickVolunteersDetails() }}>
              <div className="icon-callForVolunteers">
                
              </div>
            </Link>
            

          </div>
          
       

        </div>



        <div className="txt--normal txt--page-title vr">Past Work</div>

        <div className="cssgrid--container vr--x2">
          <div className="cssgrid--project">
            <Link to="/bibDetails" onClick={() => { this.handleClickBib() }}>
              <div className="icon-bib"></div>
            </Link>

          </div>

          <div className="cssgrid--project">
            <Link to="/provenaDetails" onClick={() => { this.handleClickProvena() }}>
              <div className="icon-provena"></div>
            </Link>

          </div>

          <div className="cssgrid--project">
            <Link to="/walkInWoodsDetails" onClick={() => { this.handleClickWalkInWoods() }}>
              <div className="icon-walkInWoods"></div>
            </Link>

          </div>

          <div className="cssgrid--project">
            <Link to="/milewiseDetails" onClick={() => { this.handleClickMilewise() }}>
              <div className="icon-milewise"></div>
            </Link>

          </div>

          <div className="cssgrid--project">
            <Link to="/keyCollectorDetails" onClick={() => { this.handleClickKeyCollector() }}>
              <div className="icon-keyCollector"></div>
            </Link>

          </div>

          <div className="cssgrid--project">
            <Link to="/championOfTreesDetails" onClick={() => { this.handleClickChampionOfTrees() }}>
              <div className="icon-COT"></div>
            </Link>

          </div>

          <div className="cssgrid--project">
            <Link to="/roadSmartDetails" onClick={() => { this.handleClickRoadsmart() }}>
              <div className="icon-roadsmart"></div>
            </Link>

          </div>


          <div className="cssgrid--project">
            <Link to="/snowmobileDetails" onClick={() => { this.handleClickWork() }}>
              <div className="icon-snowmobile"></div>
            </Link>

          </div>
        </div>

        <div className="cssgrid--container">

          <div className="cssgrid--project">
            <Link to="/gotBalanceDetails" onClick={() => { this.handleClickGotBalance() }}>
              <div className="icon-gotBalance_gray"></div>
            </Link>

          </div>


          <div className="cssgrid--project">
            <Link to="/oaktoberDetails" onClick={() => { this.handleClickOaktober() }}>
              <div className="icon-oaktober_gray"></div>
            </Link>
            {/* <Link to="/natureConnectsDetails" onClick={() => { this.handleClickNatureConnects() }}>
              <div className="icon-natureConnects_gray"></div>
            </Link> */}

          </div>

          <div className="cssgrid--project">
            <Link to="/socialNetworkingDetails" onClick={() => { this.handleClickSocialNetworking() }}>
              <div className="icon-socialNetworking_gray"></div>
            </Link>

          </div>

        </div>

        <div className="cssgrid--container">

          <div className="cssgrid--project">
            <Link to="/rockItDetails" onClick={() => { this.handleClickRockIt() }}>
              <div className="icon-rockIt_gray"></div>
            </Link>

          </div>

          <div className="cssgrid--project">
            <Link to="/wcfpDetails" onClick={() => { this.handleClickWCFP() }}>
              <div className="icon-wcfp_gray"></div>
            </Link>

          </div>


          <div className="cssgrid--project">


          </div>
        </div>
      </div>




    );
  }
}

export default Work;


