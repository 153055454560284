import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import ReactGA from 'react-ga';
import COTDesktop from '../../assets/images/COT_desktop.png';
import COTMobile from '../../assets/images/COT_mobile.png';


class ChampionOfTrees extends Component {
    handleClickCOT() {
        ReactGA.event({
          category: 'Sites',
          action: 'Clicked on Fold Of the COT',
          label: 'Portfolio Sites'
        });
      }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (

            <div className="animated fadeIn">
            <div className="wrapper wrapper-project-details">
            <ul className="hList hList--justify">
      
                      <li>
                      <NavLink to="/keyCollectorDetails">
                            <span className="icon-back"></span>
                            </NavLink>
                      </li>
                      <li className="align-right">
      
                   
                          <ul className="hList">
                            <li className="align-right">
                            <NavLink to="/roadsmartDetails">
                            <span className="icon-next"></span>
                            </NavLink>
                            </li>                     
                          </ul>
                      
      
                      </li>
                    </ul>
                    <div className="isHidden isVisible--medium">

                        <ul className="hList hList--justify hList--middle vr--x2">
                            <li className="border--right forty">
                                <h1>Champion of Trees Online Perennial Report Website</h1>
                                <h3> <span className="txt--uppercase txt--normal">CLIENT: </span> <span className="txt--serif">The Morton Arboretum</span></h3>
                                <span className="icon-html"></span>
                                <span className="icon-less"></span>
                                <span className="icon-illustrator"></span>
                                <span className="icon-photoshop"></span>

                            </li>
                          
                            <li className="three"></li>
                            <li className="forty txt--normal">
                                <div className="highlight vr"><a target="_blank" onClick={() => { this.handleClickCOT() }} rel="noopener noreferrer" href="http://www.jenzdesigninc.com/webProjects/2014_perennialReport">View Site</a>
                                </div>
                                Designed and coded front-end perennial report to showcase accomplishments of The Morton Arboretum. Developed with Bootstrap and Less.


</li>

                        </ul>

                    </div>

                    <div className="isHidden--medium vr">
                        <h1>Champion of Trees Online Perennial Report Website</h1>
                        <h3 className="vr"> <span className="txt--uppercase txt--normal">CLIENT: </span> <span className="txt--serif">The Morton Arboretum</span></h3>
                        <div className="highlight vr"><a target="_blank" onClick={() => { this.handleClickCOT() }} rel="noopener noreferrer" href="http://www.jenzdesigninc.com/webProjects/2014_perennialReport">View Site</a>
                        </div>

                        <div className="vr txt--normal"> Designed and coded front-end perennial report to showcase accomplishments of The Morton Arboretum. Developed with Bootstrap and Less.
</div>


                        <div>
                            <span className="icon-html"></span>
                            <span className="icon-less"></span>
                            <span className="icon-illustrator"></span>
                            <span className="icon-photoshop"></span>
                        </div>
                    </div>
                </div>

                <div className="wrapper">
                    <img src={COTDesktop} alt="Champion of Trees Desktop" className="img img--fill" />
                    <img src={COTMobile} alt="Champion of Trees Mobile" className="img img--fill" />
                </div>
            </div>


        );
    }
}

export default ChampionOfTrees;


