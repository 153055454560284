import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import ReactGA from 'react-ga';


class Header extends Component {
  handleClickWork() {
    ReactGA.event({
      category: 'Navigation',
      action: 'Clicked on Work',
      label: 'Header'
    });
  }
  handleClickAbout() {
    ReactGA.event({
      category: 'Header Nav',
      action: 'Clicked on About',
      label: 'Header'
    });
  }
  handleClickContact() {
    ReactGA.event({
      category: 'Navigation',
      action: 'Clicked on Contact',
      label: 'Header'
    });
  }

  handleClickJDWork() {
    ReactGA.event({
      category: 'Navigation',
      action: 'Clicked on JD Logo',
      label: 'Header'
    });
  }
  render() {
    
    return (

        <div className="wrapper">
          <div className="header vr">
            <div className="isHidden isVisible--medium">
              <ul className="hList hList--justify">

                <li>
                  <NavLink to="/work" onClick={() => { this.handleClickJDWork() }}>
                    <div className="icon-jdinc_logo"></div>
                  </NavLink>
                </li>
                <li className="align-right">

                  <div className="isHidden isVisible--medium">
                    <ul className="hList">
                      <li className="align-right">
                        <NavLink to="/work" activeClassName="active" onClick={() => { this.handleClickWork() }}>Work</NavLink>
                      </li>

                      
                      <li className="align-right">
                        <NavLink to="/about" activeClassName="active" onClick={() => { this.handleClickAbout() }}>About</NavLink>
                        </li>

                        <li className="align-right">
                        <NavLink to="/contact" activeClassName="active" onClick={() => { this.handleClickContact() }}>Contact</NavLink>
                        </li>

                      
                    </ul>

                  </div>

                </li>
              </ul>

            </div>



            <div className="isHidden--medium">
              <div className="wrapper">
                <Link to="/work">
                  <div className="icon-jdinc_logo align-center vr"></div>
                </Link>
                <ul className="hList hList--justify hList--bottom">


                  <li className="align-center">


                    <ul className="hList">
                    <li className="">
                    <NavLink to="/work" activeClassName="active" onClick={() => { this.handleClickWork() }}>Work</NavLink>
                  </li>

                  
                  <li className="">
                  <NavLink to="/about" activeClassName="active" onClick={() => { this.handleClickAbout() }}>About</NavLink>
                        </li>
                    

                    <li className="">
                    <NavLink to="/contact" activeClassName="active" onClick={() => { this.handleClickContact() }}>Contact</NavLink>
                    </li>

                    </ul>

                  </li>
                </ul>
              </div>
            </div>

          </div>
      
          
        </div>


    );
  }
}

export default Header;


