
import React, { Component } from 'react';
import $ from 'jquery';

// import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
// import RaisedButton from 'material-ui/RaisedButton';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import { ValidatorForm } from 'react-form-validator-core';
import { TextValidator } from 'react-material-ui-form-validator';
// import 'jquery-validation';
// http://blog.teamtreehouse.com/create-ajax-contact-form


class Contact extends Component {
  
  constructor(props) {

    $(document).ready( function ($) {
      var form = $('#ajax-contact');


      $(form).submit(function (event) {
        // Stop the browser from submitting the form.
        event.preventDefault();

        // TODO
        // Serialize the form data.
        var formData = $(form).serialize();

        // Submit the form using AJAX.
        $.ajax({
          type: 'POST',
          url: $(form).attr('action'),
          data: formData
        })


      });
    
    
    });


    super(props);

    this.state = {
        formData: {
            email: '',
            name: '',
            message: '',
        },
        submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
}

handleChange(event) {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
}

handleSubmit() {
    this.setState({ submitted: true }, () => {
        setTimeout(() => this.setState({ submitted: false }), 5000);
    });
}



  render() {
    const { formData, submitted } = this.state;

    return (
      <div>
        <div className="txt--normal txt--page-title vr">Contact</div>

      <div className="form--wrapper">
      <div className="form--content">
      {/* <MuiThemeProvider > */}
       
      <ValidatorForm
      ref="form"
      onSubmit={this.handleSubmit}
      id="ajax-contact" method="post" action="mailer.php"
  >
   
      <TextValidator
           
           label="Name"
           className="input--grow"
          onChange={this.handleChange}
          name="name"
          value={formData.name}
          validators={['required']}
          errorMessages={['this field is required']}
      />
       <br />
       <TextValidator
          label="Email"
          className="input--grow"
          onChange={this.handleChange}
          name="email"
          value={formData.email}
          validators={['required', 'isEmail']}
          errorMessages={['this field is required', 'email is not valid']}
      />
      <br />
      <TextValidator
          label="Message"
          
          className="label--left input--grow"
          multiLine={true}
          onChange={this.handleChange}
          name="message"
          value={formData.message}
          
      />
      <br /><br /><br />
      <Button 
      variant="contained" 
      color="primary"
      type="submit"
          label={
              (submitted && 'Your form is submitted!')
              || (!submitted && 'Submit')
          }
          disabled={submitted}
      >
      SUBMIT
    </Button>
      {/* <Button
          type="submit"
          label={
              (submitted && 'Your form is submitted!')
              || (!submitted && 'Submit')
          }
          disabled={submitted}
      /> */}
       <br /><br /><br />
       <br /><br /><br />
       <br /><br /><br />
  </ValidatorForm>
 
  {/* </MuiThemeProvider> */}
  </div>
  </div>
  </div>
    );
  }
}


export default Contact

