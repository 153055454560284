import React, { Component } from 'react';
import ReactGA from 'react-ga';


class Footer extends Component {
  handleClickCodepen() {
    ReactGA.event({
      category: 'Navigation',
      action: 'Clicked on Codepen',
      label: 'Footer'
    });
  }
  handleClickBehance() {
    ReactGA.event({
      category: 'Navigation',
      action: 'Clicked on Behance',
      label: 'Footer'
    });
  }
  handleClickLinkedIn() {
    ReactGA.event({
      category: 'Navigation',
      action: 'Clicked on LinkedIn',
      label: 'Footer'
    });
  }
  render() {
    return (

        <footer className="Site-footer">
           
          
     
           <div className="wrapper">
           <div className="header vr--x3">
             <div className="isHidden isVisible--medium">
               <ul className="hList hList--justify">
 
                 <li className="">
                 
                     {/* <div className="icon-jdinc_logo-footer vr"></div> */}
                     <div className="txt--normal txt--letter-spacing-0 txt--uppercase txt--color-white">Jenz Design, Inc.</div>
                     <div className="txt--normal txt--letter-spacing-0 txt--color-white vr--x3">jenzdesigninc@gmail.com</div>
                  
                  
                 </li>
                 <li className="align-right">
 
                   <div className="isHidden isVisible--medium">
                     <ul className="hList">
                       <li className="align-right">
                       <a target="_blank" rel="noopener noreferrer" href="https://codepen.io/JenzDesignInc/" onClick={() => { this.handleClickCodepen() }}>
                       <span className="icon-codepen"></span>
                       </a>
                       </li>
 
                       
                       <li className="align-right">
                       <a target="_blank" rel="noopener noreferrer" href="https://www.behance.net/jenzdesigninc" onClick={() => { this.handleClickBehance() }}>
                       <span className="icon-behance"></span>
                       </a>
                         </li>
 
                         <li className="align-right">
                         <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/jenniferhiar" onClick={() => { this.handleClickLinkedIn() }}>
                         <span className="icon-linkedIn"></span>
                         </a>
                         </li>
 
                       
                     </ul>
 
                   </div>
 
                 </li>
               </ul>
 
             </div>
 
 
 
             <div className="isHidden--medium">
               <div className="wrapper align-center">

                   {/* <div className="icon-jdinc_logo-footer vr"></div> */}
                   <div className="txt--normal txt--letter-spacing-0 txt--uppercase txt--color-white">Jenz Design, Inc.</div>
                   <div className="txt--normal txt--letter-spacing-0 txt--color-white vr--x3">jenzdesigninc@gmail.com</div>
                 
                   <ul className="hList">
                   <li className="align-right">
                   <a target="_blank" rel="noopener noreferrer" href="https://codepen.io/JenzDesignInc/" onClick={() => { this.handleClickCodepen() }}>
                   <span className="icon-codepen"></span>
                   </a>
                   </li>

                   
                   <li className="align-right">
                   <a target="_blank" rel="noopener noreferrer" href="https://www.behance.net/jenzdesigninc" onClick={() => { this.handleClickBehance() }}>
                   <span className="icon-behance"></span>
                   </a>
                     </li>

                     <li className="align-right">
                     <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/jenniferhiar" onClick={() => { this.handleClickLinkedIn() }}>
                     <span className="icon-linkedIn"></span>
                     </a>
                     </li>

                   
                 </ul>
               </div>
             </div>
 
           </div>
       
           
         </div>
        </footer>
      


    );
  }
}

export default Footer;


